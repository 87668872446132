<template>
  <div class="y items-center w-full">
    <main class="y 2xl:pt-16 p-3 gap-y-10 max-w-screen-md w-full h-full">
      <h1 class="font-bold text-3xl" v-if="hasKeys">Update Password</h1>
      <div v-else class="text-base">
        <p>
          In order to use the site we need to create a keypair for you which will be used to encrypt
          and decrypt the credentials you have access to. The keypair will also be encrypted with a
          password that you need to provide. This means that if you lose your password you also lose
          access to your credentials.
        </p>
        <h1 class="mt-3 font-semibold">
          This means that if you lose your password you also lose access to your credentials. There
          is no way to reset the password without losing access.
        </h1>
      </div>
      <h2 class="text-xl font-bold mb-0">Your password should match the following rules</h2>
      <div class="y gap-y-2">
        <checkbox-role
          v-for="(rule, index) of rules"
          :key="index"
          :text="rule.text"
          :checked="rule.rule"
        />
      </div>
      <div class="x gap-x-5">
        <password-input
          class="flex-1"
          v-model="password"
          :label="hasKeys ? 'New Password' : 'Password'"
        />
        <password-input
          class="flex-1"
          v-model="password_confirmation"
          :id="'password_confirmation'"
          :placeholder="'Re-type the password'"
          :label="hasKeys ? 'Confirm New Password' : 'Confirm Password'"
        />
      </div>
      <button
        :class="{ 'cursor-not-allowed': !canSend }"
        class="button-primary text-base bg-orange"
        @click.prevent="submit"
      >
        <span>Submit</span>
      </button>
    </main>
  </div>
</template>
<script>
import CheckboxRole from "../components/CheckboxRole.vue";
import PasswordInput from "../components/PasswordInput.vue";

export default {
  name: "CreateKeys",
  components: {
    CheckboxRole,
    PasswordInput,
  },
  data() {
    return {
      password: "",
      password_confirmation: "",
      submitting: false,
    };
  },
  methods: {
    submit() {
      if (this.submitting || !this.canSend) return;

      if (this.hasKeys) {
        this.$store.dispatch("callFunctionWithPassword", { callback: this.update });
      } else {
        this.create();
      }
    },
    create() {
      this.submitting = true;
      this.axios
        .post("/keys", {
          password: this.password,
          password_confirmation: this.password_confirmation,
        })
        .then(() => {
          this.$store.state.user.has_keys = true;
          this.$router.push("/");
        })
        .finally(() => {
          this.submitting = false;
        });
    },
    update(password) {
      this.submitting = true;
      return this.axios
        .put("/keys", {
          password: this.password,
          password_confirmation: this.password_confirmation,
          old_password: password,
        })
        .then(() => {
          this.password = "";
          this.password_confirmation = "";
        })
        .finally(() => {
          this.submitting = false;
        });
    },
  },
  computed: {
    hasKeys() {
      return this.$store.state.user.has_keys;
    },
    min() {
      return this.password.length >= 8;
    },
    lowerCase() {
      return this.password.match(/[a-z]/g);
    },
    upperCase() {
      return this.password.match(/[A-Z]/g);
    },
    number() {
      return this.password.match(/[0-9]/g);
    },
    symbols() {
      return this.password.match(/[^A-Za-z0-9]/g);
    },
    same() {
      return this.password === this.password_confirmation;
    },
    rules() {
      return [
        {
          text: "Minimum 8 characters",
          rule: this.min,
        },
        {
          text: "Contains at least 1 lowercase english letter",
          rule: this.lowerCase,
        },
        {
          text: "Contains at least 1 uppercase english letter",
          rule: this.upperCase,
        },
        {
          text: "Contains at least 1 numeric character",
          rule: this.number,
        },
        {
          text: "Contains at least 1 special character",
          rule: this.symbols,
        },
        {
          text: "Passwords match",
          rule: this.same,
        },
      ];
    },
    canSend() {
      return (
        this.password &&
        this.min &&
        this.lowerCase &&
        this.number &&
        this.symbols &&
        this.same &&
        !this.submitting
      );
    },
  },
};
</script>

<template>
  <div class="y gap-y-2 text-base font-medium">
    <label :for="id" class="text-sm">{{ label }}</label>
    <div class="relative w-full">
      <input
        :id="id"
        class="input input-primary w-full"
        :value="value"
        @input="(ev) => $emit('input', ev.target.value)"
        :placeholder="placeholder"
        :type="password_shown ? 'text' : 'password'"
        :style="{ border: isError ? '2px solid rgb(239, 68, 68) !important' : '' }"
      />
      <div class="x items-center justify-center absolute bottom-0 top-0 right-3">
        <i
          class="isax text-lg ml-2"
          :class="[password_shown ? 'isax-eye-slash' : 'isax-eye']"
          style="cursor: pointer"
          @click.prevent="password_shown = !password_shown"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: () => "Password",
    },
    placeholder: {
      type: String,
      default: () => "Enter a password",
    },
    value: String,
    id: {
      type: String,
      default: () => "password",
    },
    isError: Boolean,
  },
  data() {
    return {
      password_shown: false,
    };
  },
};
</script>

<style scoped>
.input {
  border-radius: 0.25rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgba(229, 229, 229, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  --tw-text-opacity: 1;
  color: rgba(34, 34, 34, var(--tw-text-opacity));
}
</style>

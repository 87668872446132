<template>
  <div class="tools">
    <span class="permission-label mr-2">Permissions</span>
    <div class="controls">
      <v-select
        class="selector"
        :placeholder="'Select permissions'"
        :options="memberships"
        :disabled="membersSelected.length === 0"
        @option:selecting="updateMembers"
        ref="permissionsSelector"
      />
      <Button
        @clicked="deleteMembers"
        :isDisabled="membersSelected.length === 0"
        title="Delete"
        :classes="['red-button']"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Button from "./Button.vue";

export default {
  components: {
    Button,
  },
  props: {
    membersSelected: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    updateMembers(membership) {
      this.$refs.permissionsSelector.value = null;
      const value = this.value.map((member) => {
        return {
          ...member,
          membership: this.parsedMembersSelected.includes(member.id)
            ? membership
            : member.membership,
        };
      });
      this.$emit("input", value);
      this.$emit("clearSelected");
    },
    deleteMembers() {
      const value = this.value.filter((member) => !this.parsedMembersSelected.includes(member.id));
      this.$emit("input", value);
      this.$emit("clearSelected");
    },
  },
  computed: {
    parsedMembersSelected() {
      return this.membersSelected.map((el) => parseInt(el, 0));
    },
    ...mapGetters(["memberships"]),
  },
};
</script>

<style></style>

<template>
  <div class="empty-section">
    <img v-if="icon" :src="icon" alt="" />
    <img v-else src="@/assets/icons/key.svg" alt="" />
    <span class="main-title font-inter"> {{ title }}</span>
    <span class="subtitle font-inter"> {{ subtitle }}</span>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    subtitle: String,
    icon: String,
  },
};
</script>

<style></style>

<template>
  <div class="add-member-section mt-3">
    <div class="member-search">
      <v-select
        :filterable="false"
        class="selector"
        @search="getUsers"
        :clearable="false"
        :options="users"
        :placeholder="'Member Name'"
        v-model="selectedUsers"
        label="name"
        multiple
      >
        <template #open-indicator>
          <span></span>
        </template>

        <template #no-options="{ search }">
          {{ search ? "No users found." : "Start typing to search users." }}
        </template>

        <template #option="user">
          <div class="member-item">
            <img :src="user.avatar" />
            <div class="details">
              <b>{{ user.name }}</b>
              <span>@{{ user.username }}</span>
            </div>
          </div>
        </template>
      </v-select>
    </div>
    <Button
      @clicked="addMembers"
      :classes="['active', 'align-self-end']"
      :title="selectedUsers.length < 2 ? 'Add a member' : 'Add members'"
      :disabled="selectedUsers.length < 1"
    >
      <i class="isax isax-add-circle text-lg mr-2" />
    </Button>
  </div>
</template>

<script>
import Button from "@/components/Button.vue";

export default {
  props: {
    addedUsers: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    Button,
  },
  data() {
    return {
      selectedUsers: [],
      users: [],
    };
  },
  methods: {
    addMembers() {
      this.$emit("addMembers", this.selectedUsers);
      this.selectedUsers.splice(0);
      this.users.splice(0);
    },
    getUsers(search, loading) {
      loading(true);
      const exclude = this.addedUsers.map((u) => u.id);
      this.axios
        .get(`/users`, {
          params: {
            search,
            exclude,
          },
        })
        .then((resp) => {
          const { users } = resp.data;
          this.users = users;
        })
        .finally(() => {
          loading(false);
        });
    },
  },
};
</script>

<style></style>

<template>
  <label class="custom-checkbox">
    <input
      :disabled="disabled"
      :value="val"
      :checked="isChecked"
      @input="updateValue($event)"
      :type="type"
      :id="id"
    />
    <slot name="label" :id="id">
      <label :for="id"></label>
    </slot>
  </label>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: () => `custom_checkbox${new Date().getTime()}`,
    },
    val: {
      type: [String, Number],
    },
    type: {
      type: String,
    },
    value: {
      type: [Array, String],
      default: () => [],
    },
    head: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
  },
  methods: {
    updateValue($event) {
      $event.stopPropagation();

      const val = $event.target.value;

      if (this.type === "checkbox") {
        const value = [...this.value];
        const index = value.indexOf(val);
        if (index !== -1) {
          value.splice(index, 1);
          if (this.head) {
            this.$emit("unselectChildren", val);
          }
        } else {
          value.push(val);
          if (this.head) {
            this.$emit("selectAllChildren", val);
          }
        }

        this.$emit("input", value);
      } else {
        this.$emit("input", val);
      }
    },
  },
  computed: {
    isChecked() {
      if (this.type === "checkbox") {
        if (this.value.includes(this.val)) {
          return true;
        }
      } else {
        return this.value === this.val;
      }

      return false;
    },
  },
};
</script>

<style scoped>
.custom-checkbox label {
  @apply flex items-center justify-center cursor-pointer;
}

label > input[type="radio"],
label > input[type="checkbox"] {
  display: none;
  color: #ff6700;
}

label > input[type="radio"].check:checked + *::before,
label > input[type="checkbox"].check:checked + *::before {
  background: #ff6700;
  border-color: #ff6700;
  content: "\2713";
  color: white;
  font-size: 10px;
  padding-left: 2px;
}

label > input[type="radio"].error + *::before,
label > input[type="checkbox"].error + *::before {
  content: "";
  display: inline-block;
  width: 1rem;
  height: 1rem;
  margin-right: 0.3rem;
  border-radius: 50%;
  border-style: solid;
  border-width: 0.1rem;
  border-color: red;
  cursor: pointer;
}

label > input[type="radio"] + *::before,
label > input[type="checkbox"] + *::before {
  content: "";
  display: inline-block;
  width: 1rem;
  height: 1rem;
  margin-right: 0.3rem;
  border-radius: 50%;
  border-style: solid;
  border-width: 0.1rem;
  border-color: gray;
  cursor: pointer;
}

label > input[type="checkbox"]:checked + *::before,
label > input[type="radio"]:checked + *::before {
  background: radial-gradient(#ff6700 0%, #ff6700 40%, transparent 50%, transparent);
  border-color: #ff6700;
}
</style>

import { ref } from "@vue/composition-api";
import { useState } from "vuex-composition-helpers";

export default function useHaveMembers() {
  const members = ref([]);
  const { user } = useState(["user"]);

  const addMembers = (users) => {
    members.value.push(
      ...users.map((u) => ({
        ...u,
        membership: "Viewer",
      })),
    );
  };

  const updateMember = (id, membership) => {
    const idx = members.value.findIndex((u) => u.id === parseInt(id, 0));
    members.value[idx].membership = membership;
  };

  const deleteMember = (id) => {
    const idx = members.value.findIndex((u) => u.id === parseInt(id, 0));
    members.value.splice(idx, 1);
  };

  const isMe = (member) => {
    return member.id === user.value.id;
  };

  return {
    members,
    addMembers,
    updateMember,
    deleteMember,
    isMe,
  };
}

<template>
  <router-link :to="link">
    <div class="item-card">
      <div v-if="image" class="item-img" :style="{ backgroundImage: 'url(' + image + ')' }"></div>
      <div v-else class="item-img bg-orange">
        <img class="m-auto" src="@/assets/icons/add-key.svg" v-if="type === 'credential'" />
        <img class="m-auto" src="@/assets/icons/people.svg" v-else-if="type === 'group'" />
      </div>
      <div class="info">
        <span class="website line-clamp-1 max-w-full">{{ item.name }}</span>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
    },
    link: [Object, String],
    type: {
      type: String,
      validator: (val) => ["credential", "group"].includes(val),
    },
  },
  computed: {
    image() {
      if (this.item.image) {
        return `${process.env.VUE_APP_BACKEND_URL}/storage/${this.item.image.path}`;
      }
      return null;
    },
  },
};
</script>

<style scoped></style>

import axios from "axios";

const data = [{ name: "groups", path: "/credential-groups/all" }];

const getState = () => {
  const states = {};
  data.forEach((item) => {
    states[item.name] = [];
  });
  return states;
};

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const getMutations = () => {
  const mutations = {};
  data.forEach((item) => {
    mutations[`set${capitalizeFirstLetter(item.name)}`] = (state, value) => {
      state[item.name] = value;
    };
  });
  return mutations;
};

const getActions = () => {
  const mutations = {};
  data.forEach((item) => {
    mutations[`fetch${capitalizeFirstLetter(item.name)}`] = ({ commit }) => {
      return axios.get(item.path).then((resp) => {
        commit(`set${capitalizeFirstLetter(item.name)}`, resp.data);
      });
    };
  });
  return mutations;
};

const store = {
  namespaced: true,
  state: {
    ...getState(),
  },
  mutations: {
    ...getMutations(),
  },
  actions: {
    ...getActions(),
  },
};

export default store;

import { ref } from "@vue/composition-api";
import axios from "axios";

export default function useHaveImage() {
  const file = ref(null);
  const imgPrev = ref(null);
  const uploadingImage = ref(false);

  const uploadImage = () => {
    uploadingImage.value = true;
    const form = new FormData();
    form.append("image", file.value);
    return new Promise((resolve) => {
      axios
        .post("images", form)
        .then((resp) => {
          const { data } = resp;
          resolve(data.image_path);
        })
        .finally(() => {
          uploadingImage.value = false;
        });
    });
  };

  const onImageSelect = (e) => {
    [file.value] = e.target.files;
    imgPrev.value = URL.createObjectURL(file.value);
    return uploadImage();
  };

  return {
    file,
    imgPrev,
    uploadingImage,
    uploadImage,
    onImageSelect,
  };
}

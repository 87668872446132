<template>
  <cards-list
    :groups="true"
    :loading="loading"
    :noData="data.length < 1"
    :disable-pagination="settings.reachedEnd"
    :pagination-loading="loading && settings.page > 1"
    @loadMore="fetchData"
  >
    <template #no-data>
      <no-data
        :title="isListFiltered ? 'No result found' : 'You are not added to any group'"
        :subtitle="
          isListFiltered
            ? 'We didn\'t find any groups within the selected filters'
            : 'Here will appear the groups you are in'
        "
      />
    </template>

    <template>
      <ItemCard
        v-for="item in data"
        :key="item.id"
        :item="item"
        :type="'group'"
        :link="{ name: 'EditGroup', params: { id: item.id } }"
      />
    </template>
  </cards-list>
</template>

<script>
import ItemCard from "@/components/ItemCard.vue";
import { mapActions, mapGetters } from "vuex";
import CardsList from "../../components/CardsList.vue";
import NoData from "../../components/NoData.vue";

export default {
  components: {
    ItemCard,
    CardsList,
    NoData,
  },
  mounted() {
    if (this.data.length < 1 && !this.isListFiltered && !this.loading) {
      this.fetchData();
    }
  },
  methods: {
    ...mapActions("credentialGroups", ["fetchData"]),
  },
  computed: {
    isListFiltered() {
      return this.filters.query.length > 0;
    },
    ...mapGetters("credentialGroups", ["data", "loading", "filters", "settings"]),
  },
};
</script>

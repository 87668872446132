<template>
  <div class="w-screen h-screen fixed inset-0 transition-all duration-200 z-modal">
    <div
      @click="close"
      class="popup-backdrop fixed inset-0 bg-gray-500 bg-opacity-75 transition-all"
      aria-hidden="true"
    ></div>
    <div
      class="modal-content border-none bg-white rounded-lg absolute y overflow-visible items-center left-1/2 top-1/2 transform -translate-y-1/2 -translate-x-1/2 w-full transition-all duration-200 shadow-xl"
      :style="`width: ${width}; height: ${height}`"
    >
      <slot name="default" />
      <div
        class="w-full py-2 px-4 bg-gray-100 border-t rounded-b-lg border-gray-200"
        :style="{ textAlign }"
        v-if="!hideTooltip"
      >
        <button @click="close" class="px-3 py-2 text-sm rounded text-gray-500">Cancel</button>
        <slot name="actionButton" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: () => "40%",
    },
    height: {
      type: String,
      default: () => "66.66%",
    },
    hideTooltip: {
      type: Boolean,
      default: false,
    },
    textAlign: {
      type: String,
      default: "right",
      validator: (value) => ["right", "left", "center"].indexOf(value) > -1,
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 768px) {
  .modal-content {
    width: 100% !important;
    border-radius: 0 !important;
  }
}
</style>

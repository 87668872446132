<template>
  <div class="attachment-card">
    <span class="name">{{ name }}</span>

    <div class="others">
      <span class="size">{{ size }}</span>

      <div class="tools">
        <div @click="$emit('download')" class="tool" title="Download" v-if="showDownload">
          <i
            class="isax text-lg mr-1"
            :class="[downloading ? 'isax-refresh rotate' : 'isax-arrow-circle-down']"
          />
          <span>Download</span>
        </div>
        <div @click="$emit('remove')" class="tool" title="Remove">
          <i class="isax isax-minus-cirlce text-lg mr-1" />
          <span>Remove</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utils from "../utils/utils";

export default {
  props: {
    name: {
      type: String,
    },
    bytes: {
      type: String,
    },
    showDownload: Boolean,
    downloading: Boolean,
  },

  computed: {
    size() {
      return utils.getSize(this.bytes);
    },
  },
};
</script>

<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <transition name="popup">
    <div
      class="fixed z-10 inset-0 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
      v-show="show"
    >
      <div
        class="flex justify-center items-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <transition name="bg-opacity">
          <div
            v-show="show"
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
          ></div>
        </transition>

        <transition name="modal-opacity">
          <div
            v-show="show"
            class="bg-white w-full rounded-lg text-left fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          >
            <div class="bg-white px-4 pb-3 sm:p-2 sm:pb-4">
              <div class="sm:flex sm:items-start">
                <div class="pt-4 sm:mt-0 sm:ml-4 sm:text-left">
                  <div class="flex gap-x-3 items-center">
                    <div
                      class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10"
                    >
                      <i class="isax isax-key text-lg text-gray-600" />
                    </div>
                    <h3 class="text-lg font-medium leading-6 text-gray-900" id="modal-title">
                      Password required
                    </h3>
                  </div>
                  <div class="mt-3 text-left">
                    <div class="text-sm text-gray-500">
                      <p class="mb-1">
                        This action requires that you provide the password to your keys.
                      </p>
                      <p>
                        You can choose to remember your password. If you do so you won't be prompted
                        to enter your password until you refresh the page in your browser.
                      </p>
                      <p class="mt-1">
                        Otherwise your password will only be remembered on the current page.
                      </p>
                    </div>
                    <div class="flex flex-col flex-grow mr-4 mt-3 text-small font-medium">
                      <password-input class="mt-2" v-model="password" :isError="isError" />
                      <span v-if="isError" class="text-xs text-red-500 mt-2">
                        The password is incorrect.
                      </span>
                    </div>
                    <div class="flex gap-2 mt-4">
                      <input
                        type="checkbox"
                        v-model="remember"
                        class="checkbox-primary input-primary"
                        id="checkbox"
                      />
                      <label class="leading-tight text-gray-500 text-xs" for="checkbox"
                        >Remember my password until I refresh the page</label
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="bg-gray-50 px-4 py-3 sm:px-6 flex flex-col md:flex-row-reverse gap-3">
              <button
                class="flex-1 md:flex-none inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-orange disabled:bg-opacity-60 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:text-sm"
                :disabled="!password"
                @click.prevent="setPassword"
              >
                Save
              </button>
              <button
                class="flex-1 md:flex-none inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:text-sm"
                @click.prevent="hideModal"
              >
                Cancel
              </button>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </transition>
</template>
<script>
import PasswordInput from "./PasswordInput.vue";

export default {
  name: "PasswordModal",
  components: { PasswordInput },
  data() {
    return {
      password: null,
      remember: false,
    };
  },
  computed: {
    show() {
      return this.$store.state.showPasswordModal;
    },
    isError() {
      return this.$store.state.passwordHaveError;
    },
  },
  methods: {
    hideModal() {
      this.$store.state.showPasswordModal = false;
      this.$store.state.passwordCallback = null;
      this.$store.state.passwordCallbackArgs = [];
      this.password = null;
      this.remember = false;
    },
    setPassword() {
      this.$store.state.password = this.password;
      this.$store.state.rememberPassword = this.remember;
      this.$store.dispatch("callFunctionWithPassword", {
        callback: this.$store.state.passwordCallback,
        args: this.$store.state.passwordCallbackArgs,
        leavePassword: this.$store.state.leavePassword,
      });
      this.hideModal();
    },
  },
};
</script>
<style scoped>
.bg-opacity-enter-active,
.modal-opacity-enter-active {
  @apply transition-opacity ease-out duration-300;
}
.bg-opacity-leave-active,
.modal-opacity-leave-active {
  @apply transition-opacity ease-in duration-200;
}
.bg-opacity-enter,
.bg-opacity-leave-to {
  opacity: 0;
}
.bg-opacity-enter-to,
.bg-opacity-leave {
  opacity: 100;
}

.modal-opacity-enter,
.modal-opacity-leave-to {
  @apply opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95;
}
.modal-opacity-enter-to,
.modal-opacity-leave {
  @apply opacity-100 translate-y-0 sm:scale-100;
}
.input {
  border-radius: 0.25rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgba(229, 229, 229, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  --tw-text-opacity: 1;
  color: rgba(34, 34, 34, var(--tw-text-opacity));
}
</style>

<template>
  <div>
    <div class="flex w-full">
      <slot class="flex-1" />
      <div class="pr-4" style="color: #bbbbbb">
        <slot name="toggler" :opened="opened">
          <i class="isax isax-arrow-up-2 text-lg" @click="$emit('close')" v-if="opened" />
          <i class="isax isax-arrow-down-1 text-lg" @click="$emit('open')" v-else />
        </slot>
      </div>
    </div>
    <transition name="height">
      <div class="overflow-hidden" v-if="opened">
        <slot name="content" />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    opened: Boolean,
  },
};
</script>

<style>
.height-enter-active,
.height-leave-active {
  transition: all 0.3s;
  max-height: 230px;
}
.height-enter,
.height-leave-to {
  max-height: 0px;
}
</style>

import axios from "axios";
import Vue from "vue";
import Vuex from "vuex";
import alerts from "./modules/alert";
import toasts from "./modules/toasts";
import credentials from "./modules/credentials";
import credentialGroups from "./modules/credentialGroups";
import data from "./modules/data";

Vue.use(Vuex);

const memberships = ["Viewer", "Editor", "Owner"];

export default new Vuex.Store({
  state: {
    user: null,
    token: null,
    userLoading: false,
    firstLoad: true,
    toastNotifications: [],
    toastCount: 0,
    password: null,
    rememberPassword: false,
    showPasswordModal: false,
    passwordPromise: null,
    passwordCallback: null,
    passwordCallbackArgs: [],
    passwordHaveError: false,
    leavePassword: false,
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
      this.dispatch("saveUser", user);
    },
    setToken(state, token) {
      state.token = token;
      this.dispatch("saveToken", token);
    },
  },
  getters: {
    token: (state) => state.token,
    isLoggedIn: (state) => state.token && state.token !== "null",
    memberships: () => memberships,
  },
  actions: {
    async callFunctionWithPassword(
      { state, dispatch },
      { callback, args = [], leavePassword = false },
    ) {
      if (state.password) {
        state.passwordHaveError = false;
        try {
          await callback(...args, state.password);
        } catch (e) {
          state.password = null;
          if (e.response.status === 400) {
            state.passwordHaveError = true;
            dispatch("callFunctionWithPassword", { callback, args, leavePassword });
            return;
          }
        }
        if (!state.rememberPassword && !leavePassword) {
          state.password = null;
        }
      } else {
        state.showPasswordModal = true;
        state.passwordCallback = callback;
        state.passwordCallbackArgs = args;
        state.leavePassword = leavePassword;
      }
    },
    fetchToken({ commit }) {
      const token = localStorage.getItem("accessToken");
      commit("setToken", token);
    },
    saveToken({ state }) {
      localStorage.setItem("accessToken", state.token);
    },
    fetchUser({ commit, dispatch, state }) {
      dispatch("fetchToken");
      if (this.getters.isLoggedIn) {
        const user = localStorage.getItem("user");
        try {
          commit("setUser", JSON.parse(user));
        } catch (e) {
          //
        }
        state.userLoading = true;
        return axios
          .get("/users/me")
          .then((resp) => {
            commit("setUser", resp.data);
          })
          .catch()
          .finally(() => {
            state.userLoading = false;
          });
      }
      return null;
    },
    saveUser({ state }) {
      localStorage.setItem("user", JSON.stringify(state.user));
    },
    logout({ commit }) {
      axios({ method: "post", url: "/auth/logout" });
      commit("setUser", "");
      commit("setToken", "");
    },
  },
  modules: {
    alerts,
    toasts,
    credentials,
    data,
    credentialGroups,
  },
});

import axios from "axios";

const getParamsFromObject = (obj) => {
  const nullRemoved = { ...obj };

  Object.keys(nullRemoved).forEach((key) => {
    if (nullRemoved[key] === null || nullRemoved[key] === "") delete nullRemoved[key];

    if (Array.isArray(nullRemoved[key])) {
      const arr = nullRemoved[key];
      arr.forEach((e, index) => {
        nullRemoved[`${key}[${index}]`] = e;
      });
      delete nullRemoved[key];
    }

    if (nullRemoved[key] === true || nullRemoved[key] === false)
      nullRemoved[key] = nullRemoved[key] ? 1 : 0;
  });

  return new URLSearchParams(nullRemoved).toString();
};

const getListStore = (path, defaultFilters) => ({
  namespaced: true,
  state: {
    data: [],
    filters: {
      ...defaultFilters,
    },
    settings: {
      page: 1,
      reachedEnd: false,
      loading: false,
    },
  },
  getters: {
    data: (state) => state.data,
    settings: (state) => state.settings,
    loading: (state) => state.settings.loading,
    page: (state) => state.settings.page,
    filters: (state) => state.filters,
  },
  mutations: {
    setData(state, data) {
      state.data = data;
    },
    setFilters(state, filters) {
      state.filters = filters;
    },
    setSettings(state, settings) {
      state.settings = settings;
    },
  },
  actions: {
    fetchData({ commit, state }, reset = false) {
      if (reset) {
        commit("setSettings", { reachedEnd: false, page: 1 });
      }

      const filters = {
        ...state.filters,
        order_by: state.filters.order_by?.toLowerCase() || "",
      };

      const params = getParamsFromObject(filters);

      commit("setSettings", { ...state.settings, loading: true });
      return axios
        .get(`/${path}?${params}&page=${state.settings.page}`)
        .then((response) => {
          const pagination = response.data;
          if (state.settings.page === 1) {
            commit("setData", pagination.data);
          } else {
            commit("setData", [...state.data, ...pagination.data]);
          }
          if (pagination.per_page > pagination.data.length) {
            commit("setSettings", { ...state.settings, reachedEnd: true });
          }
          commit("setSettings", {
            ...state.settings,
            page: state.settings.page + 1,
          });
        })
        .finally(() => {
          commit("setSettings", { ...state.settings, loading: false });
        });
    },
  },
});

export default getListStore;

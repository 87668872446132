<template>
  <div class="member">
    <div class="data">
      <CustomCheckbox
        v-if="editable"
        :value="value"
        @input="(val) => $emit('input', val)"
        :val="member.id"
        type="checkbox"
        :id="'member_' + member.id"
      >
        <template #label="{ id }">
          <label :for="id">
            <span class="font-open-sans name ms-3">
              {{ member.name }}
            </span>
          </label>
        </template>
      </CustomCheckbox>
      <span class="font-open-sans name ms-3" v-else>{{ member.name }}</span>
      <DropdownItem :ref="'memberDropdown' + member.id" :reverse="true" v-if="editable">
        <span class="font-open-sans permission permission-edit ms-1">{{ member.membership }}</span>

        <template #toggled="{ close }">
          <div v-if="editable">
            <div class="pl-4 flex flex-col gap-y-2">
              <div
                class="cursor-pointer"
                :class="[member.membership === membership ? 'text-black' : 'text-gray-300']"
                v-for="(membership, idx) in memberships"
                :key="idx"
                @click="
                  updateMember(membership);
                  close();
                "
              >
                {{ membership }}
              </div>
            </div>
            <div>
              <DropdownItem :reverse="true" :translateY="'translate-y-2'">
                <div
                  class="flex items-center border-t rounded-b-md border-gray-200 gap-x-2 mt-2 py-2 pl-2 cursor-pointer hover:text-white hover:bg-orange"
                >
                  <i class="isax isax-close-circle" /> Remove Permission
                </div>
                <template #toggled>
                  <div class="flex flex-col">
                    <div class="p-2 flex items-center gap-x-2 text-gray-600">
                      <i class="isax isax-info-circle" />
                      Are you sure you want to remove permission?
                    </div>

                    <div class="flex">
                      <div
                        @click="
                          deleteMember();
                          close();
                        "
                        class="flex-1 text-center py-2 cursor-pointer rounded-bl-md hover:text-white hover:bg-orange"
                      >
                        Yes
                      </div>
                      <div
                        @click="close()"
                        class="flex-1 text-center py-2 cursor-pointer rounded-br-md hover:text-white hover:bg-orange"
                      >
                        No
                      </div>
                    </div>
                  </div>
                </template>
              </DropdownItem>
            </div>
          </div>
        </template>
      </DropdownItem>
      <span v-else class="font-open-sans permission ms-1">{{ member.membership }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CustomCheckbox from "./CustomCheckbox.vue";
import DropdownItem from "./DropdownItem.vue";

export default {
  components: { CustomCheckbox, DropdownItem },
  props: {
    /**
     * {
     *  id,
     *  membership,
     *  name,
     * }
     */
    member: Object,
    editable: Boolean,
    value: [Array, String],
  },
  methods: {
    updateMember(permission) {
      this.$emit("update", permission);
    },
    deleteMember() {
      this.$emit("delete");
    },
  },
  computed: {
    ...mapGetters(["memberships"]),
  },
};
</script>

<template>
  <div class="credential-list">
    <div class="items-center w-full h-full">
      <main class="s-6 w-full h-full">
        <loading-list v-if="loadingFirstPage" />
        <div v-else-if="noData">
          <slot name="no-data" />
        </div>
        <div v-else class="credentials-section">
          <slot />
        </div>
        <infinite-loader
          v-if="!noData && !loadingFirstPage"
          :disabled="disablePagination"
          :loading="loading"
          @loadMore="$emit('loadMore')"
        />
      </main>
    </div>
  </div>
</template>

<script>
import InfiniteLoader from "./InfiniteLoader.vue";
import LoadingList from "./LoadingList.vue";

export default {
  components: { LoadingList, InfiniteLoader },
  props: {
    loading: Boolean,
    noData: Boolean,
    groups: Boolean,
    disablePagination: {
      type: Boolean,
      default: () => true,
    },
    paginationLoading: Boolean,
  },
  computed: {
    loadingFirstPage() {
      return this.loading && !this.paginationLoading;
    },
  },
};
</script>

<style></style>

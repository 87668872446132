<template>
  <div class="item-card">
    <div class="item-img">
      <skeleton-text class="w-full h-full" />
    </div>
    <div class="info">
      <skeleton-text class="w-8/12 h-4" />
    </div>
  </div>
</template>

<script>
import SkeletonText from "./SkeletonText.vue";

export default {
  components: { SkeletonText },
};
</script>

<template>
  <div class="y h-screen">
    <password-modal />
    <WebOrigoERPNavbar
      class="h-14 md:h-16"
      :user="user"
      :userMenuItems="userMenuItems"
      @logout="logout"
    />
    <div class="flex-1 scroll-container x 2-grow overflow-x-hidden text-black">
      <div
        aria-live="polite"
        aria-atomic="true"
        style="position: relative; min-height: 0; z-index: 99999"
      >
        <div id="toast-holder" style="position: fixed; top: 0; right: 0" class="pt-2">
          <div
            v-for="notification in [
              ...$store.state.toastNotifications,
              ...$store.state.toasts.toasts,
            ]"
            :key="notification.id"
          >
            <toast-success
              v-if="notification.status === 'Success'"
              :message="notification.message"
              :id="notification.id"
              :duration="5000"
            />
            <toast-error
              v-else
              :message="notification.message"
              :id="notification.id"
              :duration="5000"
            />
          </div>
        </div>
      </div>
      <div class="flex flex-1 w-full flex-col md:x">
        <WebOrigoERPSidebar :items="sidebarItems">
          <CredentialIcon class="weborigo-sidebar__item__main-icon-container__icon" />
        </WebOrigoERPSidebar>
        <div
          class="bg-gray-100 flex-1 y overflow-y-scroll overflow-x-hidden scrollbar-thin scrollbar-thumb-gray-300 scrollbar-thumb-rounded-full scrollbar-track-transparent"
        >
          <SectionHead class="mb-3" v-if="['Credentials', 'Groups'].includes($route.name)" />
          <main class="flex-1">
            <router-view class="flex-1" v-if="!$store.state.userLoading" />
            <div v-else class="flex-1 flex justify-content-center align-items-center w-full">
              <loading />
            </div>
          </main>
          <WebOrigoERPFooter class="bg-white h-auto md:h-28 self-end" />
        </div>
      </div>

      <transition name="popup">
        <alert
          v-for="alert of alerts"
          v-bind:key="alert.key"
          :title="alert.title"
          :message="alert.message"
          :onConfirm="() => alert.onConfirm()"
          :onCancel="() => alert.onCancel()"
          :alertKey="alert.key"
        />
      </transition>
    </div>
  </div>
</template>

<script lang="ts">
import { WebOrigoERPNavbar } from "@weborigo/erp-navbar";
import { WebOrigoERPSidebar } from "@weborigo/erp-sidebar";
import { WebOrigoERPFooter } from "@weborigo/erp-footer";
import ToastSuccess from "./components/ToastSuccess.vue";
import ToastError from "./components/ToastError.vue";
import PasswordModal from "./components/PasswordModal.vue";
import Alert from "./components/Alert.vue";
import SectionHead from "./components/SectionHead.vue";
import CredentialIcon from "./components/icons/CredentialIcon.vue";

export default {
  name: "App",
  components: {
    WebOrigoERPNavbar,
    WebOrigoERPSidebar,
    WebOrigoERPFooter,
    ToastSuccess,
    ToastError,
    PasswordModal,
    Alert,
    SectionHead,
    CredentialIcon,
  },
  data() {
    return {
      sidebarItems: [
        {
          title: "Credentials",
          route: "/credentials",
          icon: "isax-key-square",
        },
        {
          title: "Groups",
          route: "/credential-groups",
          icon: "isax-scanning",
        },
      ],
    };
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
      this.$router.push("/auth/login");
    },
  },
  computed: {
    alerts() {
      return this.$store.state.alerts.alerts;
    },
    user() {
      return this.$store.state.user;
    },
    userMenuItems() {
      const menu = [];

      if (this.user && this.user.has_keys) {
        menu.push({
          text: "Edit master password",
          icon: "isax-key-square",
          route: { name: "CreateKeys" },
        });
      }

      return menu;
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/app.scss";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  font-size: 0.875rem;
  overflow: hidden !important;
  overflow-y: hidden !important;
  font-family: "Open Sans", sans-serif;
}

#app {
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

a:hover {
  color: inherit;
  text-decoration: none;
}

.scroll-container {
  height: calc(100vh - 3.5rem);
}
@media (min-width: 768px) {
  .scroll-container {
    height: calc(100vh - 4rem);
  }
}

@media (min-width: 768px) {
  .scroll-container {
    height: calc(100vh - 4rem);
  }
}
</style>

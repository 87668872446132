<template>
  <div
    class="cursor-pointer py-2 x items-center justify-center h-full border-b-2"
    :class="active ? 'text-orange font-bold border-orange' : 'bg-white text-gray-500'"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    active: Boolean,
  },
};
</script>

<style></style>

<template>
  <button
    :id="id"
    :disabled="isDisabled || loading"
    @click="buttonClicked"
    class="custom-button"
    :class="classes.join(' ')"
  >
    <slot></slot>
    <span>{{ title }}</span>
    <i v-if="loading" class="isax isax-refresh rotate ml-2" />
  </button>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
    classes: {
      type: Array,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      required: false,
    },
    loading: {
      type: Boolean,
      required: false,
    },
  },
  methods: {
    buttonClicked() {
      this.$emit("clicked");
    },
  },
};
</script>

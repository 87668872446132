import store from "../store/index";

function getErrorMessage(response) {
  let errorMessage = "";

  switch (response.status) {
    case 500:
      errorMessage = "Error from the server";
      break;
    case 422:
      {
        const { errors } = response.data;
        Object.getOwnPropertyNames(errors).forEach((field) => {
          const errorsArray = errors[field];
          errorMessage += errorsArray.join(" ");
        });
      }
      break;

    default:
      errorMessage = response.data?.message;
      break;
  }

  return errorMessage;
}

function showMessage(message, group, error = false) {
  store.dispatch("showToast", {
    type: error ? "error" : "success",
    message,
    group,
  });
}

function showError(error) {
  store.dispatch("showToast", {
    type: "error",
    message: getErrorMessage(error),
  });
}

function showConfirm({ title, message }) {
  return new Promise((resolve) => {
    store.dispatch("showAlert", {
      title,
      message,
      onConfirm() {
        resolve(true);
      },
    });
  });
}

function getSize(bytes) {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 Byte";
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 0);
  return `${Math.round(bytes / 1024 ** i, 2)} ${sizes[i]}`;
}

function backOrRedirect(vue, route) {
  const originRoute = { ...vue.$route };
  vue.$router.back();
  // Of course, we must detect until a $nextTick is reached
  vue.$nextTick(() => {
    // If the route is not changed, we do the redirect
    if (originRoute.name === vue.$route.name) {
      // Redirect to the home path by default
      vue.$router.replace(route || "/");
    }
  });
}

export default {
  showError,
  showMessage,
  showConfirm,
  getSize,
  backOrRedirect,
};

<template>
  <div class="credentials-section">
    <loading-card v-for="i in 100" :key="i" />
  </div>
</template>

<script>
import LoadingCard from "./LoadingCard.vue";

export default {
  components: { LoadingCard },
};
</script>

<template>
  <div>
    <div class="w-full h-full flex items-center justify-center" v-if="loading">
      <Loading />
    </div>
    <div class="y gap-y-5" v-else>
      <div class="group-page">
        <div class="head">
          <div v-if="canEdit && !editMode" class="buttons-section">
            <Button @clicked="editMode = true" :classes="['plain-button', 'mr-2']" title="Edit" />
            <Button
              @clicked="$router.push({ name: 'Groups' })"
              :classes="['plain-button', 'mr-2']"
              title="Back"
            />
          </div>
          <div v-else-if="canEdit" class="buttons-section">
            <Button
              @clicked="deleteGroup"
              v-if="!isAdd && canDelete"
              :loading="deleting"
              title="Delete"
              :classes="['red-button', 'mr-auto']"
            />
            <Button
              :loading="submitting"
              @clicked="submit"
              :classes="['main-button', 'mr-2']"
              title="Save"
            />
            <Button @clicked="cancel" :classes="['plain-button', 'mr-2']" title="Cancel" />
          </div>
          <div class="content-section">
            <div class="img-section">
              <div class="loading" v-if="uploadingImage">
                <Loading />
              </div>
              <img v-if="imgPrev" :src="imgPrev" alt="" />
              <div v-if="editMode" class="upload-image" @click="$refs.fileInput.click()">
                <i class="isax isax-gallery-export text-2xl"></i>
                <input
                  type="file"
                  accept="image/png,image/jpeg,image/jpg"
                  class="file-input"
                  ref="fileInput"
                  @change="onFileChange"
                />
              </div>
            </div>
            <div class="right-side">
              <template v-if="editMode">
                <FieldItem class="mb-2" :showLabel="false" :errors="getErrors('name')">
                  <template #default="{ isError }">
                    <input
                      :value="item.name"
                      @change="(e) => (item.name = e.target.value)"
                      class="name font-inter mb-2 form-control"
                      type="text"
                      placeholder="Group Name"
                      :class="{ error: isError }"
                    />
                  </template>
                </FieldItem>

                <FieldItem :showLabel="false" :errors="getErrors('description')">
                  <template #default="{ isError }">
                    <textarea
                      v-model="item.description"
                      rows="4"
                      class="form-control desc font-open-sans"
                      placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Senectus arcu nisl congue in justo.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Senectus arcu nisl congue in justo.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Senectus arcu nisl congue in justo."
                      :class="{ error: isError }"
                    />
                  </template>
                </FieldItem>
              </template>
              <template v-else>
                <span class="name font-inter">{{ item.name }}</span>
                <span class="desc font-open-sans">{{ item.description }}</span>
              </template>
            </div>
          </div>
        </div>

        <div class="member-section mt-5">
          <div class="head" v-if="members.length > 0">
            <h2 class="title font-open-sans">{{ members.length }} Members with in this group</h2>
            <members-updater
              v-if="editMode && canEditAccess"
              v-model="members"
              :membersSelected="membersSelected"
            />
          </div>

          <div class="content">
            <div class="members mt-4">
              <editable-member
                v-for="(member, idx) in members"
                :key="'group-member-' + idx"
                :member="member"
                @update="(p) => updateMember(member.id, p)"
                @delete="() => deleteMember(member.id)"
                :editable="editMode && canEditAccess && !isMe(member)"
                v-model="membersSelected"
              />
            </div>
            <users-searcher
              v-if="editMode && canEditAccess"
              :addedUsers="members"
              @addMembers="addMembers"
            />
          </div>
        </div>
      </div>

      <div class="bg-white w-full h-auto p-5 y gap-y-3" v-if="!isAdd">
        <div class="x justify-between">
          <b class="text-3xl">Credentials</b>
          <router-link
            v-if="canAdd"
            :to="{ name: 'GroupAddCredential', params: { groupId: this.item.id } }"
          >
            <Button :classes="['main-button']">
              <i class="isax isax-add-circle text-xl mr-1" />
              <span>Add New</span>
            </Button>
          </router-link>
        </div>
        <cards-list
          :loading="credentialsLoading"
          :noData="credentials.length < 1"
          :disable-pagination="true"
        >
          <template #no-data>
            <no-data
              :title="'No credentials added'"
              :subtitle="'We didn\'t find any credentials withing this group'"
            />
          </template>

          <template>
            <ItemCard
              v-for="item in credentials"
              :key="item.id"
              :item="item"
              :link="{ name: 'EditCredential', params: { id: item.id } }"
              :type="'credential'"
            />
          </template>
        </cards-list>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import * as Validator from "validatorjs";
import Button from "@/components/Button.vue";
import ItemCard from "@/components/ItemCard.vue";
import CardsList from "../../components/CardsList.vue";
import NoData from "../../components/NoData.vue";
import FieldItem from "../../components/FieldItem.vue";
import useHaveImage from "../../composition/useHaveImage";
import useHaveMembers from "../../composition/useHaveMembers";
import Loading from "../../components/Loading.vue";
import utils from "../../utils/utils";
import EditableMember from "../../components/EditableMember.vue";
import UsersSearcher from "../../components/UsersSearcher.vue";
import MembersUpdater from "../../components/MembersUpdater.vue";

export default {
  components: {
    Button,
    FieldItem,
    Loading,
    ItemCard,
    CardsList,
    NoData,
    EditableMember,
    UsersSearcher,
    MembersUpdater,
  },
  data() {
    return {
      loading: false,
      membersSelected: [],
      editMode: false,
      memberName: null,
      toBeRemoved: null,
      item: {
        name: null,
        description: null,
        image: null,
        users: [],
      },
      errors: {},
      submitting: false,
      credentials: [],
      credentialsLoading: false,
      deleting: false,
    };
  },
  setup() {
    const { file, imgPrev, uploadingImage, uploadImage, onImageSelect } = useHaveImage();
    const { members, addMembers, updateMember, deleteMember, isMe } = useHaveMembers();

    return {
      file,
      imgPrev,
      uploadingImage,
      uploadImage,
      onImageSelect,
      members,
      addMembers,
      updateMember,
      deleteMember,
      isMe,
    };
  },
  mounted() {
    if (this.isAdd) {
      this.editMode = true;
    }
    this.initValues();
  },
  methods: {
    initValues() {
      if (this.isAdd) {
        this.editMode = true;
        Object.assign(this.item, {
          name: null,
          description: null,
          image: null,
        });
        this.members = [];
        this.imgPrev = null;
      } else {
        this.getGroup();
      }
    },
    getGroup() {
      this.loading = true;
      this.axios
        .get(`credential-groups/${this.id}`)
        .then((resp) => {
          const group = resp.data.credential_group;
          Object.assign(this.item, {
            ...group,
            image: group.image?.path,
          });
          if (this.item.image) {
            this.imgPrev = `${process.env.VUE_APP_BACKEND_URL}/storage/${this.item.image}`;
          }
          this.editMode = false;
          this.members = this.item.accesses.map((a) => ({
            id: a.user.id,
            name: a.user.name,
            membership: a.membership,
          }));
          this.getCredentials();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getCredentials() {
      this.credentialsLoading = true;
      this.axios
        .get(`credential-groups/${this.id}/credentials`)
        .then((resp) => {
          const { data } = resp;
          this.credentials = data;
        })
        .finally(() => {
          this.credentialsLoading = false;
        });
    },
    async onFileChange(e) {
      const img = await this.onImageSelect(e);
      this.item.image = img;
    },
    removeImage() {
      this.imgPrev = null;
      this.item.image = null;
    },
    removeMemeber(id) {
      this.toBeRemoved = id;
    },
    back() {
      this.$router.push({ name: "Groups" });
    },
    cancel() {
      if (this.isAdd) {
        this.back();
      } else {
        this.editMode = false;
      }
    },
    validate() {
      this.validator = new Validator(this.item, {
        name: "required",
      });

      if (this.validator.passes()) {
        this.errors = null;
      } else this.errors = this.validator.errors.all();

      return this.validator.passes();
    },
    getErrors(key) {
      return this.errors ? this.errors[key] : [];
    },
    submit() {
      const valid = this.validate();
      if (!valid) return;
      this.$store.dispatch("callFunctionWithPassword", { callback: this.save });
    },
    save(password) {
      let route = "credential-groups";
      if (!this.isAdd) {
        route += `/${this.id}?_method=PUT`;
      }
      this.submitting = true;
      return this.axios
        .post(route, {
          ...this.item,
          users: this.members.map(({ id, membership }) => ({
            id,
            membership,
          })),
          password,
        })
        .then(() => {
          this.fetchGroups(true);
          this.fetchDataGroups(true);
          this.back();
        })
        .finally(() => {
          this.submitting = false;
        });
    },
    confirmDeleteGroup() {
      return utils.showConfirm({
        title: "Delete Group",
        message: `Are you sure you want to delete this group?`,
      });
    },
    async deleteGroup() {
      const confirmed = await this.confirmDeleteGroup();
      if (!confirmed) return;

      this.deleting = true;
      this.axios
        .delete(`credential-groups/${this.id}`)
        .then(() => {
          this.fetchGroups(true);
          this.fetchDataGroups(true);
          this.back();
        })
        .finally(() => {
          this.deleting = false;
        });
    },
    ...mapActions("credentialGroups", { fetchGroups: "fetchData" }),
    ...mapActions("data", { fetchDataGroups: "fetchGroups" }),
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    isAdd() {
      return this.$route.name === "AddGroup";
    },
    user() {
      return this.$store.state.user;
    },
    myMembership() {
      const me = this.members.find((m) => m.id === this.user.id);
      if (me) return me.membership;
      return null;
    },
    canEdit() {
      if (this.isAdd) return true;
      return this.myMembership === "Editor" || this.myMembership === "Owner";
    },
    canEditAccess() {
      if (this.isAdd) return true;
      return this.myMembership === "Owner";
    },
    canDelete() {
      return this.myMembership === "Owner";
    },
    canAdd() {
      return this.myMembership === "Owner";
    },
  },
  watch: {
    $route() {
      this.$nextTick(this.initValues);
    },
  },
};
</script>

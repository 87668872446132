<template>
  <div class="section-head">
    <div class="add-new">
      <button
        :class="{ active: ['AddCredential', 'AddGroup'].includes($route.name) }"
        @click="$router.push({ name: inCredentialsRoutes ? 'AddCredential' : 'AddGroup' })"
      >
        <i class="isax isax-add-circle text-xl" />
        <span>Add New</span>
      </button>
    </div>
    <div class="search-bar-container" :class="{ full: !showSort }" v-if="showSearch">
      <Searchbar :value="filterQuery" v-debounce:300ms="(val) => (filterQuery = val)" />
    </div>
    <div class="sort-by" v-if="showSort">
      <span>Sort by</span>
      <v-select
        class="selector"
        :deselectFromDropdown="true"
        :placeholder="'Choose group'"
        :options="groups"
        label="name"
        :reduce="(g) => g.id"
        v-model="filterGroup"
        :class="{ 'have-value': filterGroup !== null }"
      />
    </div>
  </div>
</template>

<script>
import Searchbar from "@/components/Searchbar.vue";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

export default {
  components: {
    Searchbar,
  },
  data() {
    return {};
  },
  mounted() {
    if (this.groups.length < 1) {
      this.fetchGroups();
    }
  },
  methods: {
    ...mapActions("data", ["fetchGroups"]),
    ...mapActions("credentials", {
      fetchCredentials: "fetchData",
    }),
    ...mapMutations("credentials", { setCredentialsFilters: "setFilters" }),
    ...mapActions("credentialGroups", {
      fetchCredentialGroups: "fetchData",
    }),
    ...mapMutations("credentialGroups", { setCredentialGroupsFilters: "setFilters" }),
  },
  computed: {
    showSort() {
      return this.$route.name === "Credentials";
    },
    showSearch() {
      return this.$route.name === "Credentials" || this.$route.name === "Groups";
    },
    isCredentials() {
      return this.showSort;
    },
    inCredentialsRoutes() {
      const credentialsRoute = ["Credentials", "AddCredential", "EditCredential"];
      return credentialsRoute.includes(this.$route.name);
    },
    filterGroup: {
      get() {
        if (this.credentialsFilters.groups.length > 0) return this.credentialsFilters.groups[0];
        return null;
      },
      set(group) {
        this.setCredentialsFilters({
          ...this.credentialsFilters,
          groups: group ? [group] : [],
        });
      },
    },
    filterQuery: {
      get() {
        if (this.isCredentials) return this.credentialsFilters.query;
        return this.credentialGroupsFilters.query;
      },
      set(query) {
        if (this.isCredentials) {
          this.setCredentialsFilters({
            ...this.credentialsFilters,
            query,
          });
        } else {
          this.setCredentialGroupsFilters({
            query,
          });
        }
      },
    },
    ...mapGetters("credentials", { credentialsFilters: "filters" }),
    ...mapGetters("credentialGroups", { credentialGroupsFilters: "filters" }),
    ...mapState("data", ["groups"]),
  },
  watch: {
    credentialsFilters() {
      this.fetchCredentials(true);
    },
    credentialGroupsFilters() {
      this.fetchCredentialGroups(true);
    },
  },
};
</script>

<template>
  <div class="x items-center gap-x-4">
    <div class="flex h-7 items-center">
      <input
        id="comments"
        name="comments"
        type="checkbox"
        class="h-5 w-5 rounded-full border-gray-300 text-orange focus:ring-orange"
        disabled
        :checked="checked"
      />
    </div>
    <div class="ml-3 text-base">
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: String,
    checked: {},
  },
};
</script>

<template>
  <button
    class="px-3 py-2 text-sm rounded-md shadow-sm font-medium bg-orange hover:bg-opacity-90 text-white disabled:bg-opacity-70"
  >
    <slot />
  </button>
</template>

<script lang="ts">
export default {
  props: {
    color: {
      type: String,
      default: "",
    },
  },
};
</script>

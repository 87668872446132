<template>
  <div>
    <div class="w-full h-full flex items-center justify-center" v-if="loading">
      <Loading />
    </div>
    <div class="add-credential" v-else>
      <div class="head">
        <div class="left-side">
          <div class="upload-image-section" :class="{ 'custom-img': imgPrev }">
            <div class="loading" v-if="uploadingImage">
              <Loading />
            </div>
            <template v-if="imgPrev">
              <img :src="imgPrev" alt="" class="img-fluid" />
            </template>
            <template v-else>
              <img src="@/assets/icons/add-key.svg" alt="" />
            </template>
            <input
              type="file"
              accept="image/png,image/jpeg,image/jpg"
              class="file-input"
              ref="fileInput"
              @change="onFileChange"
            />
            <div
              v-if="canEdit && editMode"
              class="w-full upload-buttons-container"
              :class="{ flex: imgPrev }"
            >
              <div class="flex-1 upload-button-section" @click="$refs.fileInput.click()">
                <i class="isax isax-gallery-export text-white text-2xl mr-1"></i>
                <span class="font-open-sans">Upload image</span>
              </div>
              <div class="flex-1 upload-button-section" v-if="imgPrev" @click="removeImage">
                <i class="isax isax-trash text-lg text-white" />
                <span class="font-open-sans">Remove image</span>
              </div>
            </div>
          </div>
          <div class="w-3/12">
            <FieldItem :showLabel="false" :errors="getErrors('name')" #default="{ isError }">
              <input
                class="form-control name"
                type="text"
                placeholder="Name"
                :class="{ error: isError }"
                v-model="item.name"
                :disabled="!canEdit || !editMode"
              />
            </FieldItem>
          </div>
        </div>
        <div class="right-side">
          <Button
            v-if="canEdit"
            @click.native="editMode ? submit() : toggleEditMode()"
            :classes="['main-button', 'mr-2']"
            :title="editMode ? (isAdd ? 'Save' : 'Update') : 'Edit'"
            :loading="submitting"
          />
          <Button
            @click.native="editMode && !isAdd ? toggleEditMode() : back()"
            :classes="['plain-button']"
            :title="editMode ? 'Cancel' : 'Back'"
          />
        </div>
      </div>
      <div class="main-body mt-4">
        <div class="flex gap-5 flex-col lg:flex-row">
          <div class="flex-1 mb-3">
            <div class="info">
              <div class="flex mb-3" v-if="editMode || item.url">
                <div class="w-1/2">
                  <label>Website</label>
                </div>
                <div class="flex-1 text-left w-1/2">
                  <span @click="addURL = false" class="link-data" v-if="addURL">Add URL</span>
                  <FieldItem v-else :showLabel="false" :errors="getErrors('url')">
                    <template #default="{ isError }">
                      <div class="input-group" :class="{ disabled: !editMode }">
                        <input
                          type="text"
                          placeholder="http://"
                          class="form-control"
                          :class="{ error: isError }"
                          v-model="item.url"
                          :disabled="!canEdit || !editMode"
                        />
                      </div>
                    </template>
                  </FieldItem>
                </div>
              </div>
              <div class="flex mb-3" v-if="editMode || item.login">
                <div class="w-1/2">
                  <label>Login</label>
                </div>
                <div class="flex-1 text-left w-1/2">
                  <span @click="addLogin = false" class="link-data" v-if="addLogin">Add Login</span>
                  <FieldItem v-else :showLabel="false" :errors="getErrors('login')">
                    <template #default="{ isError }">
                      <div class="input-group" :class="{ disabled: !editMode }">
                        <input
                          type="text"
                          placeholder="example@weborigo.eu"
                          class="form-control"
                          :class="{ error: isError }"
                          v-model="item.login"
                          :disabled="!canEdit || !editMode"
                        />
                      </div>
                    </template>
                  </FieldItem>
                </div>
              </div>
              <div class="flex flex-col lg:flex-row mb-3">
                <div class="w-1/2">
                  <label>Password</label>
                </div>
                <div class="flex-1">
                  <FieldItem :showLabel="false" :errors="getErrors('data')">
                    <template #default="{ isError }">
                      <div class="input-group" :class="{ disabled: !editMode }">
                        <textarea
                          class="form-control shadow-none password rounded-scrollbar"
                          placeholder="Password"
                          :type="showPassword ? 'text' : 'password'"
                          :class="{ error: isError, 'obscure-text': !showPassword && item.data }"
                          v-model="item.data"
                          :disabled="!canEdit || !editMode"
                          rows="3"
                          v-if="editMode"
                        />
                        <div v-else>
                          <p
                            class="whitespace-pre-line pl-3 text-base font-mono"
                            :class="{ 'obscure-text': !showPassword && item.data }"
                          >
                            {{ item.data }}
                          </p>
                          <input class="hidden" type="text" ref="passwordText" :value="item.data" />
                        </div>
                        <div class="absolute right-2 top-2 flex gap-x-1.5" v-if="!isError">
                          <i
                            v-if="!editMode"
                            title="Copy"
                            class="isax text-lg"
                            :class="[copiedPassword ? 'isax-clipboard-tick' : 'isax-document-copy']"
                            style="cursor: pointer"
                            @click.prevent="copyPassword"
                          />
                          <i
                            :title="showPassword ? 'Hide' : 'Show'"
                            class="isax text-lg"
                            :class="[showPassword ? 'isax-eye-slash' : 'isax-eye']"
                            style="cursor: pointer"
                            @click.prevent="showPassword = !showPassword"
                          />
                        </div>
                      </div>
                    </template>
                  </FieldItem>
                </div>
              </div>
              <div class="flex flex-col lg:flex-row mb-3">
                <div class="w-1/2">
                  <label>Note</label>
                </div>
                <div class="flex-1">
                  <FieldItem :showLabel="false" :errors="getErrors('notes')">
                    <template #default="{ isError }">
                      <div class="input-group" :class="{ disabled: !editMode }">
                        <textarea
                          type="text"
                          placeholder="Must visit often to stay up to date"
                          class="form-control rounded-scrollbar"
                          :class="{ error: isError }"
                          :style="{ height: editMode ? 'auto !important' : '' }"
                          v-model="item.notes"
                          :disabled="!canEdit || !editMode"
                          rows="3"
                          v-if="editMode"
                        />
                        <p class="pl-3 text-base" v-else>{{ item.notes }}</p>
                      </div>
                    </template>
                  </FieldItem>
                </div>
              </div>
              <div class="flex mb-3">
                <div class="flex-1">
                  <label>Attachment</label>
                </div>
                <div class="flex-1 text-left">
                  <span
                    v-if="canEdit && editMode"
                    @click="$refs.attachmentInput.click()"
                    class="link-data"
                    >Click to attach</span
                  >
                  <input
                    type="file"
                    class="file-input"
                    ref="attachmentInput"
                    multiple
                    @change="onChoosingAttachment"
                  />
                </div>
              </div>
              <LoadingAttachments v-if="loadingFiles" />
              <div class="row" v-else>
                <div class="col-12">
                  <div class="attachments rounded-scrollbar">
                    <p v-if="attachments.length < 1">No attachments added</p>
                    <AttachmentCard
                      v-for="(attachment, idx) in attachments"
                      :key="idx"
                      :bytes="attachment.size"
                      :name="attachment.name"
                      :showDownload="!isFile(attachment)"
                      @remove="removeFile(attachment, idx)"
                      @download="download(attachment)"
                      :downloading="isDownloading(attachment)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex-1">
            <tabs class="mb-4" v-model="accessActiveTab" :values="['Groups', 'Users']" />
            <groups-editor
              v-if="accessActiveTab === 'Groups'"
              :groups="selectableGroups"
              v-model="item.groups"
              :canEdit="canEditGroups && editMode"
            />
            <div class="members flex mt-4" v-else>
              <members-updater
                v-if="canEditGroups && editMode && membersSelected.length > 0"
                v-model="members"
                @clearSelected="membersSelected.splice(0)"
                :membersSelected="membersSelected"
                class="mb-3"
              />
              <editable-member
                v-for="(member, idx) in members"
                :key="'credential-member-' + idx"
                :member="member"
                @update="(p) => updateMember(member.id, p)"
                @delete="() => deleteMember(member.id)"
                v-model="membersSelected"
                :editable="canEditGroups && editMode"
              />
              <span class="text-xs" v-if="members.length === 0">No users added.</span>
              <p class="mt-5" v-if="canEditGroups && editMode">Add Member:</p>
              <users-searcher
                v-if="canEditGroups && editMode"
                :addedUsers="members"
                @addMembers="addMembers"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { saveAs } from "file-saver";
import { mapActions, mapState } from "vuex";
import * as Validator from "validatorjs";
import Button from "@/components/Button.vue";
import AttachmentCard from "@/components/AttachmentCard.vue";
import FieldItem from "../../components/FieldItem.vue";
import utils from "../../utils/utils";
import LoadingAttachments from "../../components/LoadingAttachments.vue";
import Loading from "../../components/Loading.vue";
import useHaveImage from "../../composition/useHaveImage";
import useHaveMembers from "../../composition/useHaveMembers";
import Tabs from "../../components/Tabs.vue";
import GroupsEditor from "../../components/GroupsEditor.vue";
import EditableMember from "../../components/EditableMember.vue";
import MembersUpdater from "../../components/MembersUpdater.vue";
import UsersSearcher from "../../components/UsersSearcher.vue";

export default {
  components: {
    Button,
    AttachmentCard,
    FieldItem,
    LoadingAttachments,
    Loading,
    Tabs,
    GroupsEditor,
    EditableMember,
    MembersUpdater,
    UsersSearcher,
  },
  data() {
    return {
      editMode: false,
      addURL: true,
      addLogin: true,
      item: {
        name: null,
        data: null,
        image: null,
        url: null,
        login: null,
        notes: null,
        groups: [],
      },
      files: [],
      validator: null,
      errors: {},
      showPassword: false,
      copiedPassword: false,
      copyPasswordTimeout: null,
      loading: false,
      attachments: [],
      loadingFiles: false,
      downloadingFile: [],
      membership: null,
      submitting: false,
      accessActiveTab: "Groups",
      membersSelected: [],
    };
  },
  mounted() {
    this.initValues();
    if (this.groups.length < 1) {
      this.fetchGroups();
    }
  },
  setup() {
    const { file, imgPrev, uploadingImage, uploadImage, onImageSelect } = useHaveImage();
    const { members, addMembers, updateMember, deleteMember, isMe } = useHaveMembers();

    return {
      file,
      imgPrev,
      uploadingImage,
      uploadImage,
      onImageSelect,
      members,
      addMembers,
      updateMember,
      deleteMember,
      isMe,
    };
  },
  methods: {
    initValues() {
      if (this.isAdd) {
        this.editMode = true;
        Object.assign(this.item, {
          name: null,
          data: null,
          image: null,
          url: null,
          login: null,
          notes: null,
          groups: [],
        });
        this.attachments = [];
        this.imgPrev = null;
        this.members = [];
        if (this.addWithGroupId && this.groupId) {
          this.item.groups.push(this.groupId);
        }
      } else {
        this.loading = true;
        this.$store.dispatch("callFunctionWithPassword", { callback: this.getCredential });
      }
    },
    getCredential(password) {
      this.loading = true;
      return this.axios
        .post(`credentials/${this.id}`, {
          password,
        })
        .then((resp) => {
          this.loading = false;
          const { credential, data, membership } = resp.data;
          Object.assign(this.item, {
            ...credential,
            groups: credential.groups.map((g) => g.id),
            image: credential.image?.path,
            data,
            accesses: credential.accesses,
          });
          if (this.item.image) {
            this.imgPrev = `${process.env.VUE_APP_BACKEND_URL}/storage/${this.item.image}`;
          }
          this.addURL = this.item.url === null;
          this.addLogin = this.item.login === null;
          this.getFiles();
          this.membership = membership;
          this.members = this.item.accesses.map((a) => ({
            id: a.user.id,
            name: a.user.name,
            membership: a.membership,
          }));
          if (!this.canEdit) {
            this.addURL = false;
            this.addLogin = false;
          }
        });
    },
    getFiles() {
      this.loadingFiles = true;
      this.axios
        .get(`/credentials/${this.id}/files`)
        .then((resp) => {
          const files = this.attachments.filter(this.isFile);
          this.attachments.splice(0);
          this.attachments.push(...resp.data.data);
          this.attachments.push(...files);
        })
        .finally(() => {
          this.loadingFiles = false;
        });
    },
    async onFileChange(e) {
      const img = await this.onImageSelect(e);
      this.item.image = img;
    },
    removeImage() {
      this.imgPrev = null;
      this.item.image = null;
    },
    onChoosingAttachment(e) {
      this.attachments.push(...e.target.files);
      e.target.value = "";
    },
    submit() {
      const valid = this.validate();
      if (!valid) return;
      this.$store.dispatch("callFunctionWithPassword", { callback: this.save });
    },
    save(password) {
      this.submitting = true;
      let route = "credentials";
      if (!this.isAdd) {
        route += `/${this.id}?_method=PUT`;
      }
      return this.axios
        .post(route, {
          ...this.item,
          password,
          users: this.members,
        })
        .then((resp) => {
          const doFinally = () => {
            this.fetchCredentials(true);
            this.back();
          };
          const attachments = this.attachments.filter(this.isFile);
          if (attachments.length < 1) {
            doFinally();
            return;
          }
          const { credential } = resp.data;
          const { id } = this.isAdd ? credential : this;
          this.uploadFiles(attachments, password, id).then(doFinally);
        })
        .finally(() => {
          this.submitting = false;
        });
    },
    validate() {
      this.validator = new Validator(this.item, {
        name: "required",
        data: "required",
      });

      this.validator.setAttributeNames({ data: "Password" });

      if (this.validator.passes()) {
        this.errors = null;
      } else this.errors = this.validator.errors.all();

      return this.validator.passes();
    },
    getErrors(key) {
      return this.errors ? this.errors[key] : [];
    },
    back() {
      utils.backOrRedirect(this, { name: "Credentials" });
    },
    isFile(attachment) {
      return attachment instanceof File;
    },
    removeFile(attachment, index) {
      if (this.isFile(attachment)) {
        this.attachments.splice(index, 1);
      } else {
        this.deleteFile(attachment);
      }
    },
    async uploadFiles(files, password, id) {
      this.loadingFiles = true;
      const formData = new FormData();
      for (let i = 0; i < files.length; i += 1) {
        formData.append(`files[${i}]`, files[i]);
      }
      formData.append("password", password);
      this.axios.post(`credentials/${id ?? this.id}/files`, formData).finally(() => {
        this.loadingFiles = false;
      });
    },
    async deleteFile(file) {
      const confirmDelete = await utils.showConfirm({
        title: "Delete file",
        message: `Are you sure you want to delete this file (${file.name})?`,
      });
      if (!confirmDelete) return;

      this.loadingFiles = true;
      this.axios
        .delete(`credentials/${this.id}/files/${file.id}`)
        .then(this.getFiles)
        .finally(() => {
          this.loadingFiles = false;
        });
    },
    download(file) {
      this.$store.dispatch("callFunctionWithPassword", {
        callback: this.downloadFile,
        args: [file],
      });
    },
    async downloadFile(file, password) {
      this.downloadingFile.push(file.id);
      return this.axios
        .post(
          `files/${file.id}/download`,
          {
            password,
          },
          {
            responseType: "arraybuffer",
          },
        )
        .then((resp) => {
          const blob = new Blob([resp.data]);
          saveAs(blob, file.name);
        })
        .catch((e) => {
          utils.showMessage("The password is wrong.", null, true);
          throw e;
        })
        .finally(() => {
          const idx = this.downloadingFile.findIndex((id) => id === file.id);
          this.downloadingFile.splice(idx, 1);
        });
    },
    isDownloading(file) {
      const idx = this.downloadingFile.findIndex((id) => id === file.id);
      return idx > -1;
    },
    toggleEditMode() {
      this.editMode = !this.editMode;
    },
    copyPassword() {
      if (this.copyPasswordTimeout) clearTimeout(this.copyPasswordTimeout);
      this.copiedPassword = true;
      const copied = this.copyPasswordFromElement(this.$refs.passwordText);

      if (!copied) {
        utils.showMessage("Error copying to clipboard!", null, true);
        this.copiedPassword = false;
        return;
      }

      this.$store.state.toastNotifications.push({
        status: "Success",
        message: "Password copied to clipboard successfully!",
      });
      this.copyPasswordTimeout = setTimeout(() => {
        this.copiedPassword = false;
      }, 3000);
    },
    copyPasswordFromElement(element) {
      element.select();
      element.setSelectionRange(0, 99999);
      if (!navigator.clipboard) {
        try {
          return document.execCommand("copy");
        } catch (e) {
          return false;
        }
      }
      navigator.clipboard.writeText(element.value);
      return true;
    },
    ...mapActions("credentials", {
      fetchCredentials: "fetchData",
    }),
    ...mapActions("data", ["fetchGroups"]),
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    isAdd() {
      return this.$route.name === "AddCredential" || this.addWithGroupId;
    },
    canEdit() {
      if (this.isAdd) return true;
      return this.membership === "Editor" || this.membership === "Owner";
    },
    canEditGroups() {
      if (this.isAdd) return true;
      return this.membership === "Owner";
    },
    selectableGroups() {
      if (this.canEditGroups) {
        return this.groups.filter(
          (g) => g.accesses[0].membership === "Editor" || g.accesses[0].membership === "Owner",
        );
      }
      return this.groups;
    },
    addWithGroupId() {
      return this.$route.name === "GroupAddCredential";
    },
    groupId() {
      return this.$route.params.groupId;
    },
    ...mapState("data", ["groups"]),
  },
  watch: {
    $route() {
      this.$nextTick(this.initValues);
    },
  },
};
</script>

<style scoped></style>

<template>
  <div class="w-full flex-1 flex flex-col justify-start relative">
    <div class="w-full y gap-y-2.5 items-start mb-1">
      <div v-if="showLabel">
        <slot name="label">
          <label class="inline-block text-sm font-medium text-gray-700">{{ label }}</label
          ><span v-if="required"> *</span>
        </slot>
      </div>
      <div class="w-full flex items-start mt-0.5">
        <div class="flex-1">
          <slot :isError="isError" />
        </div>
      </div>
    </div>
    <input-error-message v-if="error != null">{{ error }}</input-error-message>
    <input-error-message v-for="(error, index) of errors" v-bind:key="index">{{
      error
    }}</input-error-message>
    <error-icon class="absolute right-4 top-3" v-if="isError" />
  </div>
</template>

<script>
import ErrorIcon from "./ErrorIcon.vue";
import InputErrorMessage from "./InputErrorMessage.vue";

export default {
  components: { InputErrorMessage, ErrorIcon },
  props: {
    label: {
      type: String,
      default: () => "",
    },
    required: Boolean,
    error: {
      type: String,
      default: null,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    showLabel: {
      type: Boolean,
      default: () => true,
    },
  },
  computed: {
    isError() {
      if (this.error) return true;
      return this.errors && this.errors.length > 0;
    },
  },
};
</script>

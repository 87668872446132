<template>
  <div class="search-bar">
    <div class="input-group mb-0">
      <input
        :value="value"
        @input="(ev) => $emit('input', ev.target.value)"
        type="text"
        class="form-control shadow-none"
        placeholder="Search"
      />
      <div class="input-group-append">
        <i class="isax isax-search-normal-1 text-2xl" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: String,
  },
};
</script>

<style scoped></style>

import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import VueAxios from "vue-axios";
import VueCompositionAPI from "@vue/composition-api";
import { ObserveVisibility } from "vue-observe-visibility";
import vueDebounce from "vue-debounce";
import vSelect from "vue-select";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./index.css";
import clickOutside from "./directives/clickOutside";
import "vue-select/dist/vue-select.css";

Vue.component("v-select", vSelect);

Vue.use(vueDebounce);

axios.defaults.baseURL = process.env.VUE_APP_BACKEND_API_URL;
axios.defaults.headers.common = {
  Accept: "Application/json",
  "Content-Type": "Application/json",
  Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
};

axios.interceptors.response.use(
  function (response) {
    if (response.data.message)
      store.state.toastNotifications.push({
        status: "Success",
        message: response.data.message,
        /* eslint-disable no-plusplus */
        id: store.state.toastCount++,
      });
    return response;
  },
  function (error) {
    if (error.response.status !== 401 && error.response.status !== 403) {
      if (error.response.data.errors) {
        /* eslint-disable no-restricted-syntax */
        /* eslint-disable guard-for-in */
        for (const field in error.response.data.errors) {
          error.response.data.errors[field].forEach((msg) =>
            store.state.toastNotifications.push({ status: "Error", message: msg }),
          );
        }
      } else if (error.response.data.message)
        store.state.toastNotifications.push({
          status: "Error",
          message: error.response.data.message,
          id: store.state.toastCount++,
        });
    } else if (error.response.status === 401) {
      store.commit("setUser", null);
      /* eslint-disable no-underscore-dangle */
      if (
        router.currentRoute._value.name &&
        (router.currentRoute._value.name !== "Login" ||
          router.currentRoute._value.name !== "Register")
      )
        router.push({ name: "Login" });
    }
    return Promise.reject(error);
  },
);

function routerLogic(to, from, next) {
  if (!store.state.user) {
    if (to.name !== "Login") {
      next({ name: "Login" });
    } else {
      next();
    }
  } else if (!store.state.user.has_keys && to.name !== "CreateKeys") {
    next({ name: "CreateKeys" });
  } else {
    next();
  }
}

router.beforeEach((to, from, next) => {
  if (store.state.firstLoad) {
    store.state.firstLoad = false;
    store
      .dispatch("fetchUser")
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        routerLogic(to, from, next);
      });
  } else if (!store.state.userLoading) {
    routerLogic(to, from, next);
  } else {
    next();
  }
});

Vue.use(Vuex);
Vue.use(VueAxios, axios);
Vue.use(VueCompositionAPI);
Vue.directive("click-outside", clickOutside);
Vue.directive("observe-visibility", ObserveVisibility);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

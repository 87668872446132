<template>
  <div class="attachments">
    <SkeletonText class="w-full h-28 rounded-md" v-for="x in 100" :key="x" />
  </div>
</template>

<script>
import SkeletonText from "./SkeletonText.vue";

export default {
  components: { SkeletonText },
};
</script>

<style></style>

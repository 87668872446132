<template>
  <div class="w-full x">
    <TabButton
      v-for="(tab, index) in values"
      :key="index"
      @click.native="changeTab(tab)"
      :classes="[]"
      class="flex-1"
      :active="tab === value"
      >{{ tab }}</TabButton
    >
  </div>
</template>

<script>
import TabButton from "./TabButton.vue";

export default {
  props: {
    value: String,
    values: {
      type: Array,
      default: () => [],
    },
  },
  components: { TabButton },
  methods: {
    changeTab(tab) {
      this.$emit("input", tab);
    },
  },
};
</script>

<style></style>

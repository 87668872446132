<template>
  <div class="row">
    <div class="col-lg-12 mt-2">
      <Searchbar v-model="search" class="w-full" />
    </div>
    <div class="col-lg-10">
      <div class="groups">
        <div class="flex flex-col gap-y-3 mt-5">
          <p v-if="filteredGroups.length < 1">No groups found.</p>
          <ToggleableCheckbox
            class="w-full"
            v-for="group in filteredGroups"
            :key="group.id"
            :opened="openedGroup === group.id"
            @open="openedGroup = group.id"
            @close="openedGroup = null"
          >
            <div class="flex w-full gap-x-2">
              <CustomCheckbox
                type="checkbox"
                :head="true"
                :val="group.id"
                :value="value"
                @input="changed"
                :disabled="!canEdit"
                :id="'group_' + group.id"
              >
                <template #label="{ id }">
                  <label class="gap-x-2" :for="id">
                    <b class="font-montserrat">{{ group.name }}</b>
                  </label>
                </template>
              </CustomCheckbox>
            </div>

            <template #content>
              <div class="flex flex-col gap-y-2.5 mt-3">
                <div class="flex gap-x-2" v-for="user in group.users" :key="'user' + user.id">
                  <CustomCheckbox
                    type="checkbox"
                    :value="[isGroupSelected(group) ? user.id : null]"
                    :val="user.id"
                    :disabled="true"
                    :id="'group_' + group.id + '_user_' + user.id"
                  />

                  <span>{{ user.name }}</span>
                </div>
              </div>
            </template>
          </ToggleableCheckbox>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Searchbar from "./Searchbar.vue";
import ToggleableCheckbox from "./ToggleableCheckbox.vue";
import CustomCheckbox from "./CustomCheckbox.vue";

export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    canEdit: Boolean,
    groups: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    Searchbar,
    ToggleableCheckbox,
    CustomCheckbox,
  },
  data() {
    return {
      search: "",
      openedGroup: null,
    };
  },
  methods: {
    changed(value) {
      this.$emit("input", value);
    },
    isGroupSelected(group) {
      return this.value.includes(group.id);
    },
  },
  computed: {
    filteredGroups() {
      if (this.search) {
        return this.groups.filter((c) => c.name.toLowerCase().match(this.search.toLowerCase()));
      }

      return this.groups;
    },
  },
};
</script>

<style></style>

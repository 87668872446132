<template>
  <div @click="toggle" v-click-outside="close" class="relative">
    <slot :isToggled="isToggled" />
    <div
      v-if="isToggled"
      @click="$event.stopPropagation()"
      ref="dropdownContent"
      class="w-56 popup h-auto rounded-md bg-white text-black absolute transform z-tooltip"
      :class="{
        '-translate-x-3/4': !showReversed,
        'translate-x-0': showReversed,
        [translateY]: true,
      }"
    >
      <slot name="toggled" :close="close" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    reverse: Boolean,
    translateY: {
      type: String,
      default: () => "translate-y-0",
    },
  },
  data() {
    return {
      isToggled: false,
      isReversed: false,
    };
  },
  computed: {
    dropdownContent() {
      return this.$refs.dropdownContent;
    },
    showReversed() {
      if (this.reverse) return true;
      return this.isReversed;
    },
  },
  methods: {
    toggle() {
      this.isToggled = !this.isToggled;
      setTimeout(() => {
        if (this.isElementOutsideScreen(this.dropdownContent)) {
          this.isReversed = true;
        }
      }, 10);
    },
    close() {
      if (this.isToggled) {
        this.isToggled = false;
      }
    },
    isElementOutsideScreen(el) {
      const rect = el.getBoundingClientRect();
      return rect.x + rect.width < 0 || rect.x > window.innerWidth || rect.x < 0;
    },
  },
};
</script>

<style scoped>
.popup {
  box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
</style>

import Vue from "vue";
import VueRouter from "vue-router";
import AddCredential from "@/views/Credential/AddCredential.vue";
import GroupsView from "@/views/CredentialGroup/GroupsView.vue";
import CredentialView from "@/views/Credential/CredentialView.vue";
import Group from "@/views/CredentialGroup/Group.vue";
import GroupList from "@/views/CredentialGroup/GroupList.vue";
import CredentialList from "../views/Credential/CredentialList.vue";
import Login from "../views/Login.vue";
import CreateKeys from "../views/CreateKeys.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/auth/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/credentials",
    component: CredentialView,
    children: [
      {
        path: "",
        name: "Credentials",
        component: CredentialList,
      },
      {
        path: "add-credential",
        name: "AddCredential",
        component: AddCredential,
      },
      {
        path: ":id/edit-credential",
        name: "EditCredential",
        component: AddCredential,
      },
    ],
  },
  {
    path: "/credential-groups",
    component: GroupsView,
    children: [
      {
        path: "",
        name: "Groups",
        component: GroupList,
      },
      {
        path: "group/add",
        name: "AddGroup",
        component: Group,
      },
      {
        path: ":id/edit-group",
        name: "EditGroup",
        component: Group,
      },
      {
        path: ":groupId/add-credential",
        name: "GroupAddCredential",
        component: AddCredential,
      },
    ],
  },
  {
    path: "/create-keys",
    name: "CreateKeys",
    component: CreateKeys,
  },

  {
    path: "/",
    redirect: "/credentials",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
